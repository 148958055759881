<template>
  <!-- <van-pull-refresh v-model="isLoading"  style="min-height:90vh"> -->
  <van-list :finished="finished" finished-text="没有更多了" @load="onLoad">
    <div v-for="(item, index) in list" :key="index">
      <div
        :class="['box', item.residueBusinessAmount || item.rapidDrugBalance ? '' : 'atv']"
        @click="handleClick(item)"
      >
        <div
          :class="{
            box_top: true,
            expired_img:
              item.isExpired == 0 ||
              (item.rapidDrugBalance == 0 && item.residueBusinessAmount == 0),
            blue: item.insType == 1,
            linghtgreen: item.insType == 2,
            green: item.insType == 3,
            lanse: item.hyb == 1,
          }"
          :style="!type ? 'justify-content:normal' : ''"
        >
          <div class="box_left">
            <img
              :src="item.insCompanyLogo.length ? item.insCompanyLogo[0].url : ''"
              alt=""
              class="card_img"
            />
            <div class="card_title" v-if="!type">{{ item.insCompanyName }}</div>
            <div class="card_num" v-if="type">{{ item.cardNumber }}</div>
          </div>
          <div class="card_text" v-if="item.isDefault == '1' && type">默认</div>
          <div class="card_more" v-if="type">更多</div>
        </div>
        <div
          class="box_footer"
          v-if="item.residueBusinessAmount || item.rapidDrugBalance"
        >
          <div class="box_price" v-if="item.residueBusinessAmount">
            <span>商保可用余额</span>
            <div>¥{{ item.residueBusinessAmount }}</div>
          </div>
          <div class="box_price" v-if="item.rapidDrugBalance">
            <span>特殊药品余额</span>
            <div>¥{{ item.rapidDrugBalance }}</div>
          </div>
        </div>
      </div>
      <!-- <div class="box_radio" v-if="type">
                <van-radio-group v-model="radio" direction="horizontal">
                    <van-radio :name="item.id" icon-size="16px">设置默认</van-radio>
                </van-radio-group>
            </div> -->
    </div>
  </van-list>
  <!-- </van-pull-refresh> -->
</template>

<script>
export default {
  props: {
    type: {
      type: Boolean,
      default: () => true,
    },
    list: {
      type: Array,
      default: () => [],
    },
    finished: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    list(val) {
      if (val.pageNum == 1) {
        this.list = val;
      } else {
        this.list.concat(val);
      }
    },
  },
  data() {
    return {
      radio: "1",
    };
  },
  methods: {
    onLoad() {
      this.$emit("onLoad");
    },
    handleClick(val) {
      this.$emit("handleClick", val);
    },
  },
};
</script>

<style scoped lang="scss">
.hiddle {
  display: none;
}
.box {
  height: vw(288);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: vw(28) 0 vw(16);
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(245, 245, 245, 1);
  border-radius: vw(8);
  box-sizing: border-box;
}
.box_left {
  display: flex;
  align-items: center;
}
.expired_img {
  background-image: url("../assets/img/isExpired.png") !important;
}
.blue {
  background-image: url("../assets/img/bgOne.png");
}
.linghtgreen {
  background-color: rgb(10, 218, 156);
}
.green {
  background-color: rgb(9, 173, 165);
}
.lanse{
  background-image: url("../assets/img/bgOne.png");
}
.box_top {
  //  background-image: url("../assets/img/bgOne.png");
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: vw(32) 0;
  align-items: center;
  color: #fff;
  font-size: vw(32);
  border-top-left-radius: vw(16);
  border-top-right-radius: vw(16);
  .card_img {
    width: vw(96);
    height: vw(96);
    border-radius: 50%;
    margin: 0 vw(12) 0 vw(24);
    flex-shrink: 0;
  }
  .card_num {
    width: vw(390);
    font-weight: 600;
  }
  .card_text {
    font-size: vw(28);
    color: #ffcd78;
    flex-shrink: 0;
  }
  .card_more {
    flex-shrink: 0;
    color: #fff;
    font-size: vw(28);
    background-image: linear-gradient(90deg, #ffd425 0%, #ff9d00 100%);
    padding: vw(6) vw(18) vw(6) vw(26);
    border-top-left-radius: vw(28);
    border-bottom-left-radius: vw(28);
  }
}
.box_footer {
  width: vw(600);
  display: flex;
  align-items: center;
  justify-content: space-around;
  // margin-right: vw(44);
  height: 100%;
  .box_price {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: vw(36);
    // font-weight: 600;
    color: #999;
    span {
      font-weight: 400;
      color: #999;
      font-size: vw(24);
      margin-bottom: vw(8);
    }
  }
}
.box_radio {
  font-size: vw(28);
  color: #505363;
  margin-bottom: vw(28);
}
.card_title {
  font-size: vw(32);
  font-weight: 600;
  color: #fff;
}
.atv {
  height: vw(160);
}
</style>
