<template>
    <div class="warranty">
        <top title="卡片列表" :right_btn="'设置卡号'" @onClickRightBtn="onClickRightBtn"></top>
        <div class="list_box">
            <Card :list="list" @onLoad="onLoad" :finished="finished" :loading="loading" :type="false"  @handleClick="handleClick" />   
        </div>
        <van-popup v-model="show" position="bottom" @close="handleClose" style="border-top-left-radius:8px;border-top-right-radius:8px;height:285px">
            <div class="popup_fixed">
                <div class="popup">
                    <span ></span>
                    <div>设置默认卡号</div>
                    <span @click="success_btn">完成</span>
                </div>
            </div>
            <div class="sli"></div>
            <div v-for="(item,index) in cardList" :key="index">
                <div class="popup_box">
                    <img :src="item.insCompanyLogo.length?item.insCompanyLogo[0].url:''" alt="">
                    <div class="popup_acc">{{item.cardNumber}}</div>
                    <van-radio-group v-model="radio" direction="horizontal">
                        <van-radio :name="item.cardNumber" icon-size="16px" @click="onRadioClick(item)"></van-radio>
                    </van-radio-group>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import Card from '@/components/warranty_card.vue'
import { getCardListS , getAllCardList ,updateDefaultPayment} from '@/api/_api/card'
export default {    
    components:{ Card },
    data(){
        return{
            show:false,
            list:[],
            cardList:[],  
            radio:'',
            newNumber:'',
            length:'',
            loading:false,
            finished:false,
        }
    },
    methods:{
        onLoad(){
            this.$toast.loading({
                message:'加载中...',
                forbidClick:true
            })
            getCardListS().then(res=>{
                this.$toast.clear();
                if(res.code == 200){
                    this.loading = false;
                    res.data.forEach(item=>{
                        item.insCompanyLogo = JSON.parse(item.insCompanyLogo)
                    })
                    this.list= res.data;
                    this.list[0].hyb=1
                    this.finished = true;
                }
            })
        },  
        onClickRightBtn(){
            this.show = true;
            getAllCardList().then(res=>{
                if(res.code == 200){
                    res.data.forEach(item=>{
                        item.insCompanyLogo = JSON.parse(item.insCompanyLogo)
                        if(item.isDefault == 1){
                            this.radio = item.cardNumber
                            this.newNumber = item.cardNumber;
                        }
                    })
                    this.cardList = res.data
                }
            })
        },
        handleClose(){
            this.radio = this.newNumber;
            this.show = false;
            
        },
        success_btn(){
            if(this.radio == this.newNumber) return this.show = false;
            let cardNum = this.radio
            // this.$dialog.confirm({
            //     title: '提示',
            //     confirmButtonColor:'#1a9ff2',
            //     message: `您确定要将尾号${cardNum.slice(cardNum.length - 4,cardNum.length)}设置为默认卡号？`,
            // })
            // .then(() => {
                updateDefaultPayment({cardNumber:cardNum}).then(res=>{
                    if(res.code == 200){
                       this.$toast.success('设置成功');
                       this.newNumber = cardNum;
                       this.show = false;
                    }
               })
            // }).catch(()=>{
            //     this.show = false;
            // })
        },
        onRadioClick(val){
            let {cardNumber,isDefault} = val;
            if(isDefault == 1) return
            this.radio = cardNumber;
        },
        handleClick(v){
            this.$router.push({path:'/warranty_list',query:{name:v.insCompanyName}})
        }
    }
}
</script>

<style lang="scss" scoped>
    .warranty{
        background: #f9f9f9;
        min-height: 100%;
    }
    .list_box{
        margin: 0 vw(24) vw(28);
        .title{
            display: flex;
            align-items: center;
            font-size: vw(32);
            color: #333;
            font-weight: 600;
        }
        .atv{
            width: vw(4);
            height: vw(32);
            background: $indexcolor;
            margin-right: vw(14);
        }
       
    }
    .popup_fixed{
        position: fixed;
        width: 100%;
        background: #fff;
        z-index: 99;
        border-top-left-radius: vw(16);
        border-top-right-radius: vw(16);
    }
    .popup{
        display: flex;
        justify-content: space-between;
        margin: vw(24) vw(24);
        color: #666;
        font-size: vw(32);
        div{
            font-size: vw(36);
            color: #333;
        }
}
.popup_text{
  font-size: vw(28);
  color: #999; 
  letter-spacing: -0.34px;
  margin-top: vw(6);
  margin-bottom: vw(24);   
  text-align: center;
}
.sli{
  height: vw(80);
}
.popup_box{
  padding: vw(24);
  border-bottom: 1px solid #f4f4f4;
  display: flex;
  align-items: center;
  img{
    width: vw(92);
    height: vw(92);
    border-radius: 50%;
    margin-right: vw(16);
  }
  .popup_acc{
    width: vw(546);
    font-size: vw(28);
    color: #333;
  }
}
</style>